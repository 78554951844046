
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LCircle, LMarker, LPolygon, LPolyline, LTooltip } from 'vue2-leaflet';
import { DARK_GREY_COLOR, MEDIUM_GREY_COLOR } from '@/constants/app.constants';
import { MessageVIDA } from '@/models/message.model';
import { Station } from '@/models/station.model';
import { GeometryUtils } from '@/utils/geometry.utils';
import { compact, flatten } from 'lodash';

@Component({
  name: 'MapCones',
  components: {
    LPolygon,
    LTooltip,
    LMarker,
    LPolyline,
    LCircle,
  },
})
export default class MapCones extends Vue {
  @Prop() public vidaMessages!: MessageVIDA[];
  @Prop() public stations!: Station[];

  public polylines: { key: string; location: number[][][]; dash: boolean; weight: number; color: string }[] = [];
  public circles: { key: string; center: number[]; radius: number; color: string }[] = [];

  public mounted() {
    this.onVidaMessagesChange();
  }

  @Watch('vidaMessages')
  public onVidaMessagesChange() {
    this.circles = compact(
      this.vidaMessages.map((message) => {
        if (message.timeSwave.max < 0) {
          return null;
        }

        return {
          key: `vida_circle_${message.id}`,
          center: [message.bazCenterLat, message.bazCenterLon],
          radius: Math.abs((message.timeP - message.timeSwave.max) * 8),
          color: DARK_GREY_COLOR,
        };
      }),
    );

    this.polylines = flatten(
      this.vidaMessages.map((message) => {
        const stationsIds = message.arrayClusterStationList
          ? message.arrayClusterStationList
          : message.arrayCluster
              .split('VIDA.{')[1]
              .split('}')[0]
              .split(',')
              .map((id) => `${id}.`);

        const stations = this.stations.filter((station) => stationsIds.includes(station.stationId));
        const [lineLong, lineLat] = GeometryUtils.rotatePoint(message.bazCenterLon, message.bazCenterLat, message.baz);

        return [
          {
            key: `vida_line_${message.id}`,
            location: stations.map((station) => [
              [message.bazCenterLat, message.bazCenterLon],
              [station.location.lat, station.location.long],
            ]),
            dash: false,
            weight: 2,
            color: MEDIUM_GREY_COLOR,
          },
          {
            key: `vida_bazline_${message.id}`,
            location: [
              [
                [message.bazCenterLat, message.bazCenterLon],
                [lineLat, lineLong],
              ],
            ],
            dash: false,
            weight: 3,
            color: DARK_GREY_COLOR,
          },
        ];
      }),
    );
  }
}
