


























































































































import _, { chain } from 'lodash';
import { Getter } from 'vuex-class';
import { LMap } from 'vue2-leaflet';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { NS_STATIONS } from '@/constants/app.constants';
import { CatalogLocation, EventAdditionalLocation, HumanizedEvent } from '@/models/event.model';
import { FilterFlags } from '@/models/map.model';
import {
  Message,
  MessageAlert,
  MessageDetectionSupplementary,
  MessageSimple,
  MessageSloBazDetermined,
  MessageSource,
  MessageVIDA,
  MessageWaveDetected,
} from '@/models/message.model';
import { PointOfInterestComplete, SeismicPosition } from '@/models/position.model';
import { Station } from '@/models/station.model';
import { getDateTime } from '@/filters/time.filter';
import { roundToDecimal } from '@/filters/number.filter';
import { MessagesService } from '@/services/messages.service';
import POIService from '@/services/poi.service';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import MapStationMarkers from '@/components/map/MapStationMarkers.component.vue';
import MapPOIMarkers from '@/components/map/MapPOIMarkers.component.vue';
import MapWaveCircles from '@/components/map/MapWaveCircles.component.vue';
import MapProxyCircles from '@/components/map/MapProxyCircles.component.vue';
import MapIntensityCircles from '@/components/map/MapIntensityCircles.component.vue';
import MapSidebar from '@/components/map/MapSidebar.component.vue';
import MapDetails from '@/components/map/MapDetails.component.vue';
import MapPolygon from '@/components/map/MapPolygon.component.vue';
import MapEpicenter from '@/components/map/MapEpicenter.component.vue';
import MapEventCatalogLocations from '@/components/map/MapEventCatalogLocations.component.vue';
import MapCones from '@/components/map/MapCones.component.vue';
import MapDrawerFilters from '@/components/shared/MapDrawerFilters.component.vue';
import MapDrawerCatalogLocation from '@/components/shared/MapDrawerCatalogLocation.component.vue';
import MapIntensityPolygons from '@/components/map/MapIntensityPolygons.component.vue';
import MapAlertCircles from '@/components/map/MapAlertCircles.component.vue';
import MapEventMarkers from '@/components/map/MapEventMarkers.component.vue';
import MapVIDA from '@/components/map/MapVIDA.component.vue';
import L from 'leaflet';

const namespace: string = 'stations';

@Component({
  name: 'RealTimeMap',
  components: {
    MapCones,
    MapEpicenter,
    MapEventCatalogLocations,
    MapIntensityPolygons,
    MapPolygon,
    MapDetails,
    MapSidebar,
    MapIntensityCircles,
    MapProxyCircles,
    MapWaveCircles,
    MapPOIMarkers,
    MapEventMarkers,
    MapStationMarkers,
    MapAlertCircles,
    MapVIDA,
    LoadingBar,
    MapDrawerFilters,
    MapDrawerCatalogLocation,
  },
})
export default class RealTimeMap extends Vue {
  @Getter('getSeismicEventInProgress', { namespace: NS_STATIONS }) public seismicEventInProgress?: boolean;

  @Prop({ default: () => [] }) public stations?: Station[];
  @Prop({ default: () => 0 }) public sizeIncrement?: number;
  @Prop({ default: () => 245 }) public mapOffset?: number;
  @Prop({ default: null }) public height?: number | null;
  @Prop({ default: () => [] }) public stationMessages?: Message[];
  @Prop({ default: () => [] }) public alertMessages?: MessageAlert[];
  @Prop({ default: () => null }) public catalogEpicenterLocation?: SeismicPosition;
  @Prop({ default: () => [] }) public hideDetails?: boolean;
  @Prop({ default: () => 7 }) public zoom?: number;
  @Prop({ default: () => false }) public isLoading?: boolean;
  @Prop({ default: () => false }) public suppressHide?: boolean;
  @Prop({ default: () => false }) public isRegionalEvent?: boolean;
  @Prop({ default: () => false }) public boundToPois?: boolean;
  @Prop({ default: () => true }) public showPoiInfo?: boolean;
  @Prop({ default: () => false }) public resizable?: boolean;
  @Prop({ default: () => false }) public showExpandMap?: boolean;
  @Prop({ default: () => [] }) public pointsOfInterest?: PointOfInterestComplete[];
  @Prop({ default: () => null }) public hoverOverStation?: string | null;
  @Prop({ default: () => false }) public enableCatalogLocations?: boolean;
  @Prop({ default: () => [] }) public catalogLocations?: EventAdditionalLocation[];
  @Prop({ default: () => [] }) public stationsInSeismicEvent?: string[];
  @Prop({ default: () => false }) public ignorePRefined?: boolean;
  @Prop({ default: () => false }) public publishMode?: boolean;
  @Prop({ default: () => [] }) public events?: HumanizedEvent[];
  @Prop({ default: () => false }) public allowBoundsChange?: boolean;

  public tileProviders = [
    {
      name: 'Default',
      visible: true,
      url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
    {
      name: 'Open Street Map',
      visible: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
    {
      name: 'Open Topographic Map',
      visible: false,
      url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
      attribution:
        'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a>',
    },
    {
      name: 'Satellite Map (no labels)',
      visible: false,
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attribution:
        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    },
    {
      name: 'ESRI',
      visible: false,
      url: 'https://services.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}',
      attribution:
        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    },
  ];

  public center: number[] = [];
  public bounds: number[][] | null = null;
  public zoomLevel: number = 0;
  public isMapFullscreen: boolean = false;

  public eventEndedAt: string = '';
  public showSOH: boolean = true;
  public hasSourceRegion: boolean = false;

  public lastEndEvent: MessageSimple | undefined = undefined;
  public lastClearAt: number = -1;

  public innerPointsOfInterest: PointOfInterestComplete[] = [];
  public alertedPointsOfInterest: PointOfInterestComplete[] = [];
  public currentSourceMessage: MessageSource | null = null;
  public waveMessages: MessageWaveDetected[] = [];
  public detectionSupplementaryMessages: MessageDetectionSupplementary[] = [];
  public sohMessages: MessageSloBazDetermined[] = [];
  public sloBazMessages: MessageSloBazDetermined[] = [];
  public vidaMessages: MessageVIDA[] = [];
  public mapAlertMessages: MessageAlert[] = [];

  public stationsLocations: number[] = [];
  public pointsOfInterestLocations: number[] = [];
  public eventLocations: number[] = [];
  public waveCirclesLocations: number[] = [];
  public proxyCirclesLocations: number[] = [];
  public polygonsLocations: any[] = [];
  public intensityPolygons: any[] = [];

  public filterFlags: FilterFlags = new FilterFlags();
  public savedZoomLevel: number = -1;
  public shouldRecalculateBounds: boolean = true;

  public mapOptions = {
    renderer: L.canvas(),
    zoomControl: false,
  };

  public mounted() {
    this.zoomLevel = this.zoom ?? 7;

    if (this.stationMessages?.length) {
      this.onMessagesChanged(this.stationMessages);
    }
    this.onPointsOfInterestChange();
    this.onAlertMessagesChange();
  }

  @Emit()
  public onCatalogLocationAdd(newCatalogLocation: CatalogLocation) {
    return newCatalogLocation;
  }

  @Watch('seismicEventInProgress')
  public onSeismicEventInProgressChange() {
    if (!this.seismicEventInProgress) {
      this.currentSourceMessage = null;
      this.sloBazMessages = [];
      this.sohMessages = [];
      this.hasSourceRegion = false;
    }
  }

  @Watch('sizeIncrement')
  public onSizeChanged(shouldRecalculateBounds: boolean = true) {
    if (this.$refs.myMap) {
      (this.$refs.myMap as LMap).mapObject.invalidateSize();
    }

    if (shouldRecalculateBounds) {
      this.calculateBounds();
    }
  }

  @Watch('pointsOfInterest')
  public onPointsOfInterestChange() {
    this.innerPointsOfInterest = _.isArray(this.pointsOfInterest) ? this.pointsOfInterest : [];
    this.alertedPointsOfInterest = this.innerPointsOfInterest.filter((poi) => !!poi.triggeredRule);
  }

  @Watch('alertMessages')
  public onAlertMessagesChange() {
    this.mapAlertMessages = _(this.alertMessages)
      .groupBy('stationId')
      .values()
      .map((messages) => {
        const message = chain(messages).sortBy(['radius', 'time']).last().value();
        return message?.radius === 0 ? undefined : message;
      })
      .compact()
      .value();
  }

  @Watch('stationMessages')
  public onMessagesChanged(messages: Message[]) {
    const innerMessages = MessagesService.getValidMessages(_.cloneDeep(messages));
    const eventsEnded = MessagesService.getEventEndedMessages(innerMessages);

    this.lastEndEvent = _.last(eventsEnded);
    if (this.lastEndEvent?.time) {
      this.eventEndedAt = getDateTime(this.lastEndEvent.time);
    }
    this.showSOH = !this.seismicEventInProgress && !this.hasEventToClear();

    this.vidaMessages = MessagesService.getVIDAMessages(innerMessages);
    this.waveMessages = MessagesService.getWaveMessages(innerMessages);
    this.detectionSupplementaryMessages = MessagesService.getDetectionSupplementaryMessages(innerMessages);
    this.sloBazMessages = MessagesService.getSloBazMessages(innerMessages, this.ignorePRefined);

    this.sohMessages = MessagesService.getSOHMessages(innerMessages);
    const sourceMessages = MessagesService.getSourceMessages(innerMessages);

    this.handleSourceMessages(sourceMessages);
  }

  public canShowProxyCircles() {
    return this.stations?.length && this.filterFlags.distance;
  }

  public canShowCones() {
    return this.stations?.length && this.filterFlags.baz;
  }

  public getMapStyle() {
    if (this.isMapFullscreen) {
      return `height: 100vh`;
    }
    if (this.height) {
      return `height: ${this.height}px`;
    }
    if (this.publishMode) {
      return `height: 703px`;
    }
    return `height: calc(100vh - ${this.mapOffset}px)`;
  }

  public onFilterFlagsChange(filterFlags: FilterFlags) {
    this.filterFlags = filterFlags;
  }

  public onZoomChange(zoomLevel: number) {
    this.zoomLevel = zoomLevel;
    if (this.savedZoomLevel !== -1) {
      this.shouldRecalculateBounds = !!this.allowBoundsChange;
    }
    if (this.savedZoomLevel === -1) {
      this.savedZoomLevel = zoomLevel;
    }
  }

  public onWaveCirclesLocationChange(locations: number[]) {
    this.waveCirclesLocations = locations;
    this.calculateBounds();
  }

  public onProxyCirclesLocationChange(locations: number[]) {
    this.proxyCirclesLocations = locations;
    this.calculateBounds();
  }

  public onPointsOfInterestLocationChange(locations: number[]) {
    this.pointsOfInterestLocations = locations;
    this.calculateBounds();
  }

  public onEventLocationChange(locations: number[]) {
    this.eventLocations = locations;
    this.calculateBounds();
  }

  public onStationsLocationChange(locations: number[]) {
    this.stationsLocations = locations;
    this.calculateBounds();
  }

  public onPolygonsLocationChange(locations: number[][][]) {
    this.polygonsLocations = locations;
    this.calculateBounds();
  }

  public onIntensityPolygonsLocationChange(locations: number[][][]) {
    this.intensityPolygons = locations;
    this.calculateBounds();
  }

  public toggleFullscreen() {
    this.isMapFullscreen = !this.isMapFullscreen;
    setTimeout(() => {
      this.onSizeChanged(true);
    }, 500);
  }

  private calculateBounds() {
    if (!this.shouldRecalculateBounds) {
      return;
    }
    this.bounds = _.union(
      this.boundToPois ? this.pointsOfInterestLocations : [],
      this.stationsLocations,
      this.polygonsLocations,
      this.intensityPolygons,
      this.eventLocations,
      _.flatten(this.waveCirclesLocations),
      _.flatten(this.proxyCirclesLocations),
      this.catalogLocations ? this.catalogLocations.map((location) => [location.gpsCoords3D.lat, location.gpsCoords3D.long]) : [],
    );
    this.onSizeChanged(false);
  }

  private hasEventToClear() {
    return this.lastClearAt !== -1;
  }

  private handleSourceMessages(messages: MessageSource[]) {
    const lastMessage = _.last(messages);
    this.currentSourceMessage = lastMessage ?? null;

    if (lastMessage) {
      this.hasSourceRegion = !!lastMessage.regions;
      this.setMapCenter(lastMessage.location);
      this.innerPointsOfInterest = POIService.setPointsOfInterestData(this.innerPointsOfInterest, lastMessage);
      this.alertedPointsOfInterest = this.innerPointsOfInterest.filter((poi) => !!poi.triggeredRule);
      this.generateSeismicEventInfo(lastMessage);
      this.calculateBounds();
    }
  }

  private setMapCenter(location: SeismicPosition | null) {
    if (location) {
      this.center = [location.lat, location.long];
    }
  }

  private generateSeismicEventInfo(sourceMessage: MessageSource) {
    if (this.currentSourceMessage) {
      _.set(
        this.currentSourceMessage,
        'computedMagnitude',
        `${roundToDecimal(sourceMessage.magnitude.min, 3)} - ${roundToDecimal(sourceMessage.magnitude.max, 3)}`,
      );
      _.set(this.currentSourceMessage, 'computedTimeSinceFirstP', `${sourceMessage.millisSinceFirstPDetected / 1000}s`);
    }
  }
}
